import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { EndrentService } from "../../../../../../services";

const useExchageListHook = () => {
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  let [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [displayData, setDisplayData] = useState(0);

  const [search, setSearch] = useState("");
  const [item, setItem] = useState("fullname");
  const [loader, setLoader] = useState("notset");
  const [listTable, setListTable] = useState([]);

  const columns = [
    {
      name: "Stock No.",
      selector: (row) => row.srnumber,
      width: "100px",
    },
    {
      name: "Customer Name",
      selector: (row) => row.fullname,
      width: "220px",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileno,
      width: "150px",
    },
    {
      name: "Start Date",
      selector: (row) => row.startdate,
      width: "180px",
    },
    {
      name: "End Date",
      selector: (row) => row.enddate,
      width: "180px",
    },
    {
      name: "No. Of Days",
      selector: (row) => row.extradays,
      width: "120px",
    },
    {
      name: "Vehicle Details",
      selector: (row) => row.vdetails,
      width: "180px",
    },
    {
      name: "Reg No.",
      selector: (row) => row.regno,
      width: "180px",
    },
    {
      name: "Paid Amount",
      selector: (row) => (row.paidamt !== 0 ? `₹${row.paidamt.toFixed(2)}` : 0),
      width: "150px",
    },
    {
      name: "Balance Amount",
      selector: (row) =>
        row.balanceamt !== 0 ? `₹${row.balanceamt.toFixed(2)}` : 0,
      width: "150px",
    },

    {
      name: "Select",
      selector: (row) => (
        <button className="viewdata" onClick={() => handleRow(row)}>
          Select
        </button>
      ),
      width: "150px",
    },
  ];
  let order = [];
  let obj = {};
  /**
   * table list
   */
  useEffect(() => {
    if (listData?.data?.data?.docs) list();
    // eslint-disable-next-line
  }, [listData?.data?.data?.docs]);

  /**
   * list call
   */
  useEffect(() => {
    bookingResponse(page, item, search);
    // eslint-disable-next-line
  }, []);

  /**
   * table list call
   */
  const list = () => {
    listData.data.data.docs.map((item, index) => {
      let startDate1 = item?.startdate ? new Date(item?.startdate) : "";
      let endDate1 = item?.enddate ? new Date(item?.enddate) : "";
      obj = {
        srnumber: item?.stocknumber,

        fullname: item?.customername,
        mobileno: item?.customermobileno,
        startdate: startDate1?.toLocaleString("en-IN"),
        enddate: endDate1?.toLocaleString("en-IN"),
        extradays: item?.noofdays,
        vdetails: item?.vehicledetailsmake + " " + item?.vehicledetailsmodel,

        paidamt: item?.paidamount,
        balanceamt: item?.pendingamount,

        id: item?._id,
        regno: item?.vehicleregnumber,
      };
      order.push(obj);
      return true;
    });
    if (order.length) setListTable(order);
  };

  /**
   * list api call
   * @param {*} page
   * @param {*} item
   * @param {*} search
   */
  const bookingResponse = (page, item, search) => {
    setLoader("loading");
    EndrentService.getrentlist(page, item, search.trim())
      .then((response) => {
        setLoader("isfull");
        setListData(response);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response.data.data.page);
      })
      .catch((err) => console.log(err));
  };

  /**
   * handle row
   * @param {*} row
   */
  // const handleRow = (row) => {
  //   navigate(`/exchange-otpScreen?bid=${row.id}`, {
  //     state: {
  //       row: row,
  //     },
  //   });
  // };
  const handleRow = (row) => {
    navigate(`/exchange-details?bid=${row.id}`, {
      state: {
        row: row,
      },
    });
  };

  /**
   * handle page change
   * @param {*} e
   */
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    bookingResponse(e.selected + 1, item, search);
  };

  /**
   * change handler
   * @param {*} e
   */
  const changeItemHandler = (e) => {
    setSearch("");
    if (e.target.value === "mobileno") {
      setSearch("");
      setItem(e.target.value);

      bookingResponse(1, e.target.value, "");
    } else {
      setItem(e.target.value);
      bookingResponse(1, e.target.value, "");
    }
  };

  /**
   * search handler
   * @param {*} e
   */
  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    bookingResponse("", item, e.target.value);
  };

  return {
    loader,
    listData,
    totalPages,
    page,
    displayData,
    search,
    columns,
    listTable,
    handlePageClick,
    changeItemHandler,
    changeSearchHandler,
  };
};
export default useExchageListHook;
